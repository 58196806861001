export const Destinations = [
  {
    id: 'feedback',
    url: 'https://laguardiaairport.com/feedback/',
  },
  {
    id: 'publicTransportation',
    url: 'https://laguardiaairport.com/to-from-airport/public-transportation',
  },
  {
    id: 'onAirportBusServices',
    url: 'https://laguardiaairport.com/at-airport/on-airport-bus',
  },
  {
    id: 'bookRide',
    url: 'https://lga.gtisnext.com/kiosk',
  },
  {
    id: 'reviewReservation',
    url: 'https://lga.gtisnext.com/welcome',
  },
  {
    id: 'stay',
    url: 'https://www.laguardiaairport.com/at-airport/airport-hotels',
  },
  {
    id: 'placesToGo',
    url: 'https://www.iloveny.com/places-to-go/',
  },
  {
    id: 'thingsToDo',
    url: 'https://www.iloveny.com/things-to-do/',
  },
  {
    id: 'events',
    url: 'https://www.iloveny.com/events/',
  },
  {
    id: 'planYourTrip',
    url: 'https://www.iloveny.com/travel-tools/',
  },
  {
    id: 'newLGAAirport',
    url: 'https://lgatb.parasyst.net/anewlga/www.anewlga.com/index.html',
  },
  {
    id: 'flightTracker',
    url: 'https://www.laguardiaairport.com/flight-search',
  },
  {
    id: 'lostAndFound',
    url: 'https://panynj.lostandfoundsite.com/LostItem.aspx?id=183dc70a494e495ba941c348ae4ad46d',
  },
  {
    id: 'airlineDirectory',
    url: 'https://laguardiaairport.com/flight/airlines',
  },
  {
    id: 'airportMap',
    url: 'https://laguardiaairport.com/at-airport/airport-maps',
  },
  {
    id: 'parking',
    url: 'https://laguardiaairport.com/to-from-airport/parking',
  },
  {
    id: 'shopsAndRestaurants',
    url: 'https://laguardiaairport.com/at-airport/shops-restaurants-and-services',
  },
]